import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {
      inforDetail: '',
      //信息详情
      skuData: '',
      //
      thumb: [],
      //
      video: '',
      //视频地址
      cover: '',
      //封面地址
      check: '',
      //
      status: '' //是否停用
    };
  },

  mounted() {
    this.information_id = this.$route.query.information_id;
    this.getInforDetailDetail();
    window.addEventListener("setItemEvent", e => {
      if (e.key === "lang") {
        this.getInforDetailDetail();
      }
    });
  },
  methods: {
    // 是否停用
    switchChange() {
      this.$http.isStopMessage({
        id: this.information_id
      }).then(res => {
        if (res.code == 1) {} else {
          this.$message.info(res.msg);
        }
      });
    },
    // 获取信息详情
    getInforDetailDetail() {
      let userId = this.$store.state.userinfo.id;
      console.log(userId);
      this.$http.informationDetail({
        user_id: userId,
        information_id: this.information_id
      }).then(res => {
        if (res.code == 1) {
          this.inforDetail = res.data;
          this.skuData = res.data.sku_data;
          let newSkuData = [];
          this.skuData.forEach((item, index) => {
            if (item.information_type == 1 && !item.value || item.information_type == 3 && !item.value) {} else {
              newSkuData.push(item);
            }
          });
          newSkuData.forEach(item => {
            if (item.sign == 'Precio') {
              item.value = '$' + item.value;
            }
            if (item.sign == 'Mantenimientos') {
              item.value = '$' + item.value;
            }
          });
          this.skuData = newSkuData;
          this.thumb = res.data.thumb;
          this.video = res.data.video;
          this.cover = res.data.cover;
          this.status = res.data.status == 1 ? false : true;
          this.check = this.inforDetail.check;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    backClick() {
      this.$router.push({
        path: '/myRelease',
        query: {
          curCenterIndex: 3
        }
      });
    },
    // 重新提交
    resubmitClick() {
      this.$router.push({
        path: '/resubmitRelease',
        query: {
          information_id: this.information_id
        }
      });
    }
  }
};