var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "all_detail"
  }, [_c('div', {
    staticClass: "detail"
  }, [_c('div', {
    staticClass: "detail_right"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "top_left"
  }, [_c('div', {
    staticClass: "title",
    on: {
      "click": _vm.backClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('title.myRelease')) + " ")]), _vm._m(0), _c('div', {
    staticClass: "sub_title"
  }, [_vm._v(_vm._s(_vm.$t('title.detail')))])])]), _c('div', {
    staticClass: "carousel"
  }, [_c('el-carousel', {
    attrs: {
      "trigger": "click",
      "height": "350px",
      "arrow": "always"
    }
  }, [_vm.video ? _c('el-carousel-item', [_c('video', {
    attrs: {
      "width": "894",
      "height": "350",
      "src": _vm.video,
      "poster": _vm.cover,
      "controls": "",
      "autoplay": true,
      "muted": ""
    },
    domProps: {
      "muted": true
    }
  })]) : _vm._e(), _vm._l(_vm.thumb, function (item, index) {
    return _c('el-carousel-item', {
      key: index
    }, [_c('viewer', {
      attrs: {
        "images": [item]
      }
    }, [_c('img', {
      key: index,
      staticStyle: {
        "object-fit": "contain",
        "width": "894px",
        "height": "350px"
      },
      attrs: {
        "src": item
      }
    })])], 1);
  })], 2)], 1), _c('div', {
    staticClass: "house_content"
  }, [_c('div', {
    staticClass: "house_title"
  }, [_c('div', {
    staticClass: "house_title_left"
  }, [_c('div', {
    staticClass: "house_name"
  }, [_vm._v(" " + _vm._s(_vm.inforDetail.title) + " ")]), _c('div', {
    staticClass: "house_two"
  }, [_vm.inforDetail.price != '0.00' ? _c('div', {
    staticClass: "price"
  }, [_vm._v(" $" + _vm._s(_vm.inforDetail.price) + " ")]) : _vm._e(), _c('div', {
    staticClass: "address",
    style: {
      marginLeft: _vm.inforDetail.price != '0.00' ? 80 + 'px' : 0 + 'px'
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/home_icons/house.png")
    }
  }), _c('div', {
    staticClass: "address_detail"
  }, [_vm._v(" " + _vm._s(_vm.inforDetail.addr_name) + " " + _vm._s(_vm.inforDetail.area_name) + " ")])])])])]), _c('div', {
    staticClass: "house_info"
  }, [_c('div', {
    staticClass: "house_time"
  }, [_vm._v(" " + _vm._s(_vm.$t('home.releaseTime')) + " " + _vm._s(_vm.inforDetail.create_time) + " ")])])]), _vm.skuData.length > 0 ? _c('div', {
    staticClass: "all_info"
  }, _vm._l(_vm.skuData, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "info_item"
    }, [_c('div', {
      staticClass: "info_item_title"
    }, [_vm._v(_vm._s(item.key_name))]), _c('div', {
      staticClass: "info_item_content"
    }, [_vm._v(_vm._s(item.select_value ? item.select_value : item.value))])]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "home_detail"
  }, [_c('div', {
    staticClass: "home_detail_content"
  }, [_vm._v(" " + _vm._s(_vm.inforDetail.detail) + " ")]), _c('div', {
    staticClass: "home_detail_imgs"
  }, [_c('div', {
    staticClass: "detail_img"
  }, [_c('viewer', {
    attrs: {
      "images": _vm.thumb
    }
  }, _vm._l(_vm.thumb, function (item, index) {
    return _c('img', {
      key: index,
      attrs: {
        "src": item
      }
    });
  }), 0)], 1)])]), _vm.check == 2 ? _c('div', {
    staticClass: "status_btn",
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c('el-switch', {
    attrs: {
      "active-text": _vm.$t('isStop'),
      "active-color": "#ff0000",
      "inactive-color": "#eeeeee"
    },
    on: {
      "change": function ($event) {
        return _vm.switchChange();
      }
    },
    model: {
      value: _vm.status,
      callback: function ($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  })], 1) : _vm._e(), _vm.check == 1 ? _c('div', {
    staticClass: "status_btn apply_in"
  }, [_vm._v(" " + _vm._s(_vm.$t('curState')) + "：" + _vm._s(_vm.$t('other.applyIn')) + " ")]) : _vm._e(), _vm.check == 2 ? _c('div', {
    staticClass: "status_btn apply_success"
  }, [_vm._v(" " + _vm._s(_vm.$t('curState')) + "：" + _vm._s(_vm.$t('other.applySuccess')) + " ")]) : _vm._e(), _vm.check == 3 ? _c('div', {
    staticClass: "status_btn apply_fail"
  }, [_vm._v(" " + _vm._s(_vm.$t('curState')) + "：" + _vm._s(_vm.$t('other.applyFailure')) + " ")]) : _vm._e(), _vm.check == 3 ? _c('div', {
    staticClass: "status_btn apply_btn",
    on: {
      "click": _vm.resubmitClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('submitAgain')) + " ")]) : _vm._e(), _vm.check == 2 ? _c('div', {
    staticClass: "status_btn apply_success2",
    on: {
      "click": _vm.resubmitClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('my.edit')) + " ")]) : _vm._e()])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/home_icons/front.png")
    }
  })]);
}];
export { render, staticRenderFns };